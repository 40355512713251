$(function() {

    var galleryTop = new Swiper('.gallery--top .swiper-container', {
        nextButton: '.gallery__arrow--right',
        prevButton: '.gallery__arrow--left',
        slideToClickedSlide: true,
        onSlideChangeStart: function(swiper, event) {
            galleryThumbs.slideTo(swiper.activeIndex);
        }
    });
    var galleryThumbs = new Swiper('.gallery--thumbs  .swiper-container', {
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        onTap: function(swiper, event) {
            galleryTop.slideTo(swiper.clickedIndex);
        }
    });

});
