$(function () {

    var body = $('body');
    body.on('click', '.hamburger', function (e) {
        var self = $(this);
        self.toggleClass('is-active');
        var target = self.data('target');
        $(target).toggleClass('is-opened');
        if(target == '.navigation__content') {
            body.toggleClass('menu-opened');
        }
        e.preventDefault();
    });


    body.on('click', '.js-open-reservation', function (e) {
        $('.c-reservation').addClass('is-opened');
        e.preventDefault();
    });

    body.on('click', '.js-close-reservation', function (e) {
        $('.c-reservation').removeClass('is-opened');
        e.preventDefault();
    });

    body.on('click', '.js-toggle-reservation', function (e) {
        $('.c-reservation').toggleClass('is-opened');
        e.preventDefault();
    });

    body.on('click', '.js-close-menu', function (e) {
        $(this).closest('.has-children').removeClass('is-opened');
        e.preventDefault();
    });

    body.on('click', '.has-children .menu__link', function (e) {
        var self = $(this);
        self.closest('.has-children').siblings('li').removeClass('is-opened');
        self.closest('.has-children').toggleClass('is-opened');
        e.preventDefault();
    });

    $('.js-datepicker').datepicker({
        dateFormat: 'dd.mm.yy'
    });

    // swiper
    new Swiper('.slider-home', {
        parallax: true,
        effect: 'fade',
        loop: true,
        // If we need pagination
        pagination: '.swiper-pagination',

        // Navigation arrows
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev'
    });

});